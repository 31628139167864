import React from 'react';
import { StyledWrapper, StyledContainer } from './ThanksText.styled';

const ThanksText = () => (
  <StyledWrapper>
    <StyledContainer>
      <span>Otrzymaliśmy Twoją wiadomość. Postaramy się odpowiedzieć najszybciej jak to możliwe.</span>
    </StyledContainer>
  </StyledWrapper>
);

export default ThanksText;
