import styled from 'styled-components';
import { breakPoints, fontWeight, colors } from 'utils/variables';
import Container from 'components/_shared/Container/Container';

export const StyledWrapper = styled.div`
  position: relative;
`;

export const StyledContainer = styled(Container)`
  span {
    position: relative;
    display: block;
    padding: 0;
    margin: 0 auto;
    max-width: 64.6rem;
    font-size: 1.8rem;
    line-height: 2.8rem;
    font-weight: ${fontWeight.medium};
    text-align: center;
    height: 12rem;
    transform: translateY(5.3rem);
    color: ${colors.grayCobalt};

    @media (min-width: ${breakPoints.tablet}) {
      height: 6rem;
    }

    @media (min-width: ${breakPoints.desktop}) {
      height: 0;
    }
  }
`;
