import React from 'react';
import Layout from 'templates/Layout/Layout';
import PageHeading from 'components/_shared/PageHeading/PageHeading';
import ContentData from 'content/thanksPage-informationOffice.json';
import InformationOffice from 'components/_shared/InformationOffice/InformationOffice';
import AloneShapes from 'components/_shared/AloneShapes/AloneShapes';
import ThanksText from 'components/_shared/ThanksText/ThanksText';

const Page = () => (
  <Layout
    metaTitle="Dziękujemy za wiadomość!"
    metaDescription="Dziękujemy za wiadomość! Odpowiedź zostanie przesłana w najbliszym czasie."
    indexing={false}
  >
    <PageHeading text="Dziękujemy" />
    <ThanksText />
    <AloneShapes />
    <InformationOffice contentData={ContentData} />
  </Layout>
);

export default Page;
